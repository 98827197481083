
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

  /**
   * @desc boite de dialogue pour confirmer la quelque chose
   */
  @Component({
    components: {
    },
  })
  export default class DialogBoxValidation extends Vue {
    boxWidth = "400px";
    visible = false;

    /**
     * @desc événement à emettre en cas de validation
     */
    @Prop()
    event : string | undefined;

    private returnData : any;

    /**
     * @desc show the rubric dialog box
     */
    public show(returnData? : any) : void {
      this.visible= true;
      this.$vuetify.theme.dark = false;
      this.returnData = returnData;
    } 

    /**
     * @desc fonction appelée en cas de validation
     */
    public validate() : void{
      setTimeout(() => {
        this.visible = false;
        this.$emit(this.event as string, this.returnData);
      }, 200);
    }

    /**
     * @desc fonction appelée en cas d'annulation. cache simplement la boite de dialogue
     */
    public cancel() : void {
      setTimeout(() => {
        this.visible = false;
      }, 200);
    }
  }


