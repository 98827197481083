
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import { MyClassroomsArticlesDto } from '@/interfaces/arcticles/MyClassroomsArticlesDto';
  import DialogBoxValidation from '@/components/DialogBoxValidation.vue';
  import { Utils } from '@/scripts/Utils';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';


  /**
   * @desc page d'affichage des articles créés par les classes associées à l'enseignant connecté
   */
  @Component({
    components: {
      'validation-dialog-box' : DialogBoxValidation
    },
  })
  export default class MyClassroom extends Vue {
    /**
     * @desc les données à afficher,
     */
    data : Array<MyClassroomsArticlesDto> = [];

    selectedClassIndex = 0;
    selected : Array<any> = [];

    private tableHeaders = [
      { text: 'Titre', value: 'title', sortable: true },
      { text: 'Auteur', value: 'authorName', sortable: true },
      { text: 'Date de création', value: 'createdAt', sortable: true},
      { text: 'Actions', sortable: false, value: 'coverImgId' },
    ];
    private loading= false;
    private tableSortBy = 'createdAt';
    private tableDesc = true;

    /**
     * @desc nom de l'événement appelé quand un article est appelé à être validé
     */
    private validationEventName = 'articleValidated';

    /**
     * @desc nom de l'événement appelé quand un article est appelé à être supprimer
     */
    private deletionEventName = 'articleDeleted';

    private displayedMsg = "";

    mounted() : void {
        if(AuthenticationService.getUserStatus() !== Consts.user_status_teacher){
        this.$router.push('/');
        return;
      }
      this.loadArticles();
    }

    /**
     * @desc charge les articles créés par les classes associées à l'enseignant connecté
     */
    loadArticles() {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getMyClassroomArticles, headers)
        .then((response) => {
          for(let classData of response.data){
            classData.articles.map((x: any) => new Article(x));
          }
          this.data = response.data;
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc retourne un string lisible de la date indiquée
     */
    private getStrDate(date : Date) : string {
      date = new Date(date);
      let day = date.getDate().toString();
      if(day.length === 1) day = '0' + day;

      return day + '/' + (date.getMonth()+1) + "/" + date.getFullYear();
    }

    /**
     * @desc mise à jour de la classe selectionnée
     */
    private selectedClassChanged() : void {
      this.selected = [];
    }

    /**
     * @desc demande la validation de la validation d'un article
     */
    private validateArticle(article : Article) {
      (this.$refs.validateArticleDialog as DialogBoxValidation).show([article]);
    } 

    /**
     * @desc ouvre la page d'édition de l'article
     */
    private editArticle(article : Article) {
      const query = Utils.createUrlQuery({type: 'class-article'});
      this.$router.push('/article/edition/' + article.id + '?' + query);
    } 

    /**
     * @desc demande la validation de la suppression d'un article
     */
    private deleteArticle(article : Article) {
      (this.$refs.deleteArticleDialog as DialogBoxValidation).show([article]);
    }

    /**
     * @desc demande la validation de la validation de tous les articles selectionnés
     */
    private validateSelected() : void {
      if(this.selected.length)
        (this.$refs.validateArticleDialog as DialogBoxValidation).show(this.selected.map(x => x));
    }

    /**
     * @desc demande la validation de la suppression de tous les articles selectionnés
     */
    private deleteSelected() : void {
      if(this.selected.length)
        (this.$refs.deleteArticleDialog as DialogBoxValidation).show(this.selected.map(x => x));
    }

    /**
     * @desc affiche un message de confirmation/erreur
     */
    private showMsg(msg : string) : void {
      const msgLabel = this.$el.querySelector("#msg-label");
      this.displayedMsg = msg;
      if(!msgLabel) return;
      msgLabel.className = "";
      setTimeout(() => {
        msgLabel.className = "invisible";
      }, 20);
    }

    /**
     * @desc demande la validation au serveur des articles indiqués
     */
    private serverValidateArticles(articles : Array<Article>) : void {
      const headers = AuthenticationService.getRequestHeader();
      for(let i=0; i < articles.length; ++i) {
        axios
        .post(UrlConsts.validateArticle, {id: articles[i].id}, headers)
        .then((response) => {
          this.showMsg('Article publié');
          this.removeArticlesFromList(articles[i].id);
        })
        .catch(error => {
          console.log(error);
          this.showMsg('Erreur lors de la publication de l\'article');
        })
      }
    }

    /**
     * @desc demande la suppression au serveur des articles indiqués
     */
    private serverDeleteArticles(articles : Array<Article>) : void {
      const headers = AuthenticationService.getRequestHeader();
      for(let i=0; i < articles.length; ++i) {
        axios
        .delete(UrlConsts.deleteArticle + '/' + articles[i].id, headers)
        .then((response) => {
          this.showMsg('Article supprimé');
          this.removeArticlesFromList(articles[i].id);
        })
        .catch(error => {
          console.log(error);
          this.showMsg('Erreur lors de suppression de l\'article');
        })
      }
    }

    /**
     * @desc enlève l'article indiqué des articles affichés
     */
    private removeArticlesFromList(articleId : number) {
      for(const classData of this.data) {
        const articleIndex = classData.articles.findIndex((x : Article) => x.id === articleId);
        if(articleIndex > -1) {
          classData.articles.splice(articleIndex, 1);
          break;
        }
      }
      this.$forceUpdate();
    }
  }
